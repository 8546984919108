import { useState } from 'react';
import AddCoreEssayDialog from '../components/myEssay/AddCoreEssayDialog';
import DeleteEssayDialog from '../components/myEssay/DeleteEssayDialog';
import {
  addStoryItemTypes,
  applicationTags,
  CIO_SHARE_ITEM_TYPES,
  googleDriveFolders,
} from '../constants/other';
import {
  essaySubTypeApplicationEssay,
  essayTypeForPlatform,
  googleLogin,
} from '../constants/staticData';
import {
  ESSAY_TYPE_CORE,
  ESSAY_TYPE_APPLICATION,
  UNIVERSILY_TEMPLATES,
} from '../firebase/constants';
import { addStoryItem } from '../firebase/services/addServices';
import { useGoogleDocs } from '../hooks';
import useEssayStore from '../stores/EssayStore';
import useMyTeamStore from '../stores/MyTeamStore';
import useProfileStore from '../stores/ProfileStore';
import useTemplateStore from '../stores/TemplateStore';
import { deleteStoryItem } from '../firebase/services/deleteServices';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { updateStoryItem } from '../firebase/services/updateServices';
import { userTypeKeywords } from '../constants/keywords';
import { useEffect } from 'react';
import CopyCoreEssayDialog from '../components/myEssay/CopyCoreEssayDialog';
import AddApplicationEssayDialog from '../components/myEssay/AddApplicationEssayDialog';
import AddCollegeDialog from '../components/myApplications/AddCollegeDialog';
import useApplicationStore from '../stores/ApplicationStore';
import useAdminStore from '../stores/AdminStore';
import AddEssayApplicationDialog from '../components/myApplications/AddEssayApplicationDialog';
import { getStoryItem } from '../firebase/services/getServices';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import useStudentStore from '../stores/StudentStore';
import { useShallow } from 'zustand/react/shallow';
import useTeamStore from '../stores/TeamStore';
import {
  addTeamUniversilyToChatParticipants,
  filterUniAndTeamUniversily,
  INVITE_STATUS,
} from '../utils/utlityTypes';
import { ShareUtility } from '../components/myHub/ShareUtility';
import { axiosPost } from '../firebase/axios';
import { ApiRoutes } from '../firebase/apis';

export const MyEssayContainer = () => {
  const essayStore = useEssayStore();
  const myTeamStore = useMyTeamStore();

  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
    }))
  );

  const selectedTeam = useTeamStore((state) => state.selectedTeam);

  const adminStore = useAdminStore();

  const userProfile = useProfileStore((state) => state.profile);

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState({
    open: false,
    message: '',
  });

  const currentSharingEssay = essayStore?.essaysById[essayStore?.shareEssayId];

  const profileStore = useProfileStore();
  const googleDocs = useGoogleDocs();
  const templateStore = useTemplateStore();
  const applicationStore = useApplicationStore();
  const [successSnackbar, setSuccessSnackbar] = useState({
    noUndoAction: false,
    message: '',
  });
  const [disableActionBtn, setDisableActionBtn] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState({});
  const [coreEssays, setCoreEssays] = useState([]);
  const [copyOption, setCopyOption] = useState('coreEssay');
  const [addProcessStep, setAddProcessStep] = useState(null);
  const [collegeName, setCollegeName] = useState('');
  const [platFormName, setPlatFormName] = useState('');
  const [whereSubmitted, setWhereSubmitted] = useState('');
  const [essayApplicationName, setEssayApplicationName] = useState('');
  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
    profileStore?.profile?.profileType !== userTypeKeywords.ADMINISTRATOR;
  const {
    isAddingEssayForApplication,
    setIsAddingEssayForApplication,
    collegeIdToCreateNewEssay,
    essayIdToCreateNewEssay,
  } = useEssayStore();

  const [selectedEssay, setSelectedEssay] = useState({});
  const studentStore = useStudentStore(
    useShallow((state) => {
      return {
        activeStudentReviewers: state.activeStudentReviewers,
        selectedStudent: state.selectedStudent,
      };
    })
  );

  // TODO
  const resyncDocDataForEssay = async (id, essayData) => {
    if (id && !isParent) {
      if (essayData) {
        const newData = await getDocDataInFormat(
          essayData?.docs?.split('/')[5]
        );
        if (newData.content !== essayData?.docInformation?.content) {
          await updateStoryItem(
            {
              id: id,
              docInformation: newData,
            },
            addStoryItemTypes.ESSAY
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!essayStore.addOrEditEssayDocs && essayStore.addOrEditEssayDocsId) {
      const essayData = essayStore.essaysById[essayStore.addOrEditEssayDocsId];
      resyncDocDataForEssay(essayStore.addOrEditEssayDocsId, essayData);
      essayStore.setAddOrEditEssayDocsId(null);
    }
    //eslint-disable-next-line
  }, [essayStore.addOrEditEssayDocs, essayStore.addOrEditEssayDocsId]);

  const getDocDataInFormat = async (id, isParent = false) => {
    const googleDocData = await googleDocs.getGoogleDoc(id, isParent);
    return {
      charCount: googleDocData.charCount,
      content: googleDocData.content,
      wordCount: googleDocData.wordCount,
    };
  };


  const onAddCoreEssay = async (essayData) => {
    const categoryLength = essayStore.essays.filter(
      (essay) => essay?.essayCategory === essayData?.essayCategory
    )?.length;

    const essayName = `${
      categoryLength === 0
        ? essayData?.essayName || 'Blank Essay'
        : `${essayData?.essayName || 'Blank Essay'} ${categoryLength} `
    }`;
    const reviewersId = (essayData?.sharedWith || []).map(
      (teamMember) => teamMember?.id
    );
    let essayId = '';
    if (profileStore.profileLogin?.loggedInType === googleLogin) {
      let templateData = await getStoryItem(UNIVERSILY_TEMPLATES, [
        {
          property: 'templateType',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: essayData.essayCategory,
        },
      ]);
      templateData = templateData?.at(0)?.templateText || '';

      if (!isParent) {
        const essayDoc = await googleDocs.createGoogleDoc(
          essayName,
          googleDriveFolders.ESSAY,
          templateData
        );
        const googleDocData = await getDocDataInFormat(essayDoc?.id);
        if (essayData?.sharedWith?.length > 0) {
          await googleDocs.giveTeamMembersAccessToDocs(
            essayDoc?.id,
            essayData?.sharedWith?.map((reviewer) => reviewer.email)
          );
        }

        essayId = await addStoryItem(
          {
            essayName,
            docs: essayDoc?.webViewLink,
            type: ESSAY_TYPE_CORE,
            owner: userProfile?.email,
            createdBy: studentStore?.selectedStudent?.uid,
            docInformation: {
              charCount: googleDocData?.charCount,
              content: googleDocData?.content,
              wordCount: googleDocData?.wordCount,
            },
            essayCategory: essayData.essayCategory || '',
            sharedWith: reviewersId,
          },
          addStoryItemTypes.ESSAY
        );
      } else {
        essayId = await addStoryItem(
          {
            essayName,
            owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
            type: ESSAY_TYPE_CORE,
            essayCategory: essayData.essayCategory,
            sharedWith: reviewersId,
            createdBy: studentStore?.selectedStudent?.uid,
          },
          addStoryItemTypes.ESSAY
        );
      }
    } else {
      essayId = await addStoryItem(
        {
          essayName,
          type: ESSAY_TYPE_CORE,
          essayCategory: essayData.essayCategory,
          sharedWith: reviewersId,
        },
        addStoryItemTypes.ESSAY
      );
    }
    let addMessage = 'Your Core Essay has been added.';
    if (essayStore.essays.length === 1) {
      addMessage = 'Awesome! You have added your first Essay!';
    }
    setSuccessSnackbar({
      noUndoAction: true,
      message: addMessage,
    });

    if (essayId && essayData?.sharedWith?.length > 0) {
      // Create chat channel for activity
      const channelMembers = addTeamUniversilyToChatParticipants([
        ...essayData?.sharedWith?.map((member) => member?.id),
        isParent ? selectedTeam?.studentUID : profileStore.profile.uid,
      ]);
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: channelMembers,
          topicName: 'Topic for ' + essayData?.essayName,
          utilityId: essayId,
          topicType: 'Essays',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }

    essayStore.setAddOrEditEssayDocsId(essayId);
    essayStore.setCurrentSelectedEssay(essayId);
    essayStore.setAddOrEditEssayDocs(true);
    essayStore.setIsActionSnackbar(true);
    const allTeamMembers = (essayData?.sharedWith || [])?.map((profile) => ({
      email: profile.email,
      id: profile.id,
      photoURL: profile?.photoURL,
      label: profile?.label,
    }))
    console.log({allTeamMembers});
    console.log("Shared with", essayData?.sharedWith);
    essayStore.setCurrentSelectedEssaySelectedMembers(allTeamMembers);
  };

  const handleShareUtility = async (teamMemberData) => {
    const payload = {
      teamId: teamStore.selectedTeam?.id,
      itemType: CIO_SHARE_ITEM_TYPES.ESSAYS.name,
      itemName: currentSharingEssay.essayName,
      userId: profileStore.profile?.uid,
      senderFirstName: profileStore.profile?.firstName,
      senderLastName: profileStore.profile?.lastName,
      senderEmail: profileStore.profile?.email,
      teamMembers: teamMemberData,
      teamName: teamStore.selectedTeam?.teamName,
      destinationLink: CIO_SHARE_ITEM_TYPES.ESSAYS.destinationLink,
    };

    const result = await axiosPost(ApiRoutes?.SHARE_ITEM, payload);

    if (result.data.status === 'success') {
      setSuccessSnackbarOpen({
        open: true,
        message: `Item shared successfully!`,
      });
      essayStore.setIsShareUtility(false);
    }
    return result;
  };

    const teamDataReviewers = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== selectedTeam?.studentEmail)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  const closeDialog = () => {
    essayStore.setIsAddingEssay(false);
    essayStore.setIsAddingCoreEssay(false);
    essayStore.setIsEditingEssay(false);
    essayStore.setIsCopyingApplicationEssay(false);
    setDisableActionBtn(false);
  };
  const onDelete = async () => {
    setSuccessSnackbar({
      noUndoAction: false,
      message: 'Your Essay was deleted.',
    });
    const deleteId = essayStore.deleteEssayId;
    await deleteStoryItem(deleteId, addStoryItemTypes.ESSAY);
    essayStore.setIsActionSnackbar(true);
  };

  const undoDeleteEssay = async () => {
    const deleteId = essayStore.deleteEssayId;
    await updateStoryItem(
      {
        id: deleteId,
        deleteDocument: false,
      },
      addStoryItemTypes.ESSAY
    );
    essayStore.setDeleteEssayId(null);
    essayStore.setIsActionSnackbar(false);
  };

  const copyCoreEssay = async (essayData) => {
    let essayId = '';
    const copyFromEssay = essayStore.essaysById[essayStore.copyEssayId];
    const { id, updatedAt, updatedBy, createdAt, ...essayDetails } =
      copyFromEssay;
    const newEssay = {
      type: essayTypeForPlatform.coreEssay,
      essayCategory: essayDetails.essayCategory,
      copiedFromId: copyFromEssay.id,
      essayName: essayData.essayName?.trim(),
      sharedWith: (essayData?.sharedWith || []).map(
        (teamMember) => teamMember?.id
      ),
    };
    if (profileStore.profileLogin?.loggedInType === googleLogin) {
      if (copyFromEssay?.docs && !isParent) {
        const docId = copyFromEssay.docs?.split('/')[5];
        const copyDoc = await googleDocs.createCopyOfGoogleDoc(
          docId,
          googleDriveFolders.ESSAY,
          essayData?.essayName
        );
        const googleDocData = await getDocDataInFormat(copyDoc?.id);
        await googleDocs.giveTeamMembersAccessToDocs(
          copyDoc?.id,
          essayData?.sharedWith?.map((reviewer) => reviewer.email)
        );
        essayId = await addStoryItem(
          {
            ...newEssay,
            owner: userProfile?.email,
            docs: copyDoc?.webViewLink,
            docInformation: {
              charCount: googleDocData?.charCount,
              content: googleDocData?.content,
              wordCount: googleDocData?.wordCount,
            },
            isCopied: true,
          },
          addStoryItemTypes.ESSAY
        );
      } else {
        if (isParent) {
          essayId = await addStoryItem(
            {
              ...newEssay,
              owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
              createdBy: userProfile?.uid,
              isCopied: true,
            },
            addStoryItemTypes.ESSAY
          );
        } else {
          essayId = await addStoryItem(
            { ...newEssay, owner: userProfile?.email },
            addStoryItemTypes.ESSAY
          );
        }
      }
    } else {
      essayId = await addStoryItem({ ...newEssay }, addStoryItemTypes.ESSAY);
    }

    if (essayId && essayData?.sharedWith?.length > 0) {
      // Create chat channel for activity
      const channelMembers = addTeamUniversilyToChatParticipants([
        ...essayData?.sharedWith?.map((member) => member?.id),
        isParent ? selectedTeam?.studentUID : profileStore.profile.uid,
      ]);
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: channelMembers,
          topicName: 'Topic for ' + essayData?.essayName,
          utilityId: essayId,
          topicType: 'Essays',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }

    setSuccessSnackbar({
      noUndoAction: true,
      message: 'A copy of your essay has been created.',
    });
    essayStore.setIsActionSnackbar(true);
    essayStore.setAddOrEditEssayDocsId(essayId);
    essayStore.setAddOrEditEssayDocs(true);
  };

  const copyApplicationEssay = async (essayData) => {
    let essayId;
    const copyFromEssay = essayStore.essaysById[essayStore.copyEssayId];
    const {
      id,
      updatedAt,
      updatedBy,
      createdAt,
      applicationName,
      collegeId,
      ...essayDetails
    } = copyFromEssay;
    let newEssay;
    const reviewersId = (essayData?.sharedWith || []).map(
      (teamMember) => teamMember?.id
    );
    if (essayData?.essaySubType === essaySubTypeApplicationEssay.college) {
      console.log('if 1');
      newEssay = {
        ...essayDetails,
        copiedFromId: copyFromEssay?.id,
        essaySubType: essayData?.essaySubType,
        collegeId: essayData?.collegeId || '',
        essayName: essayData?.essayName,
        essayReferenceId: essayData?.essayReferenceId,
        sharedWith: reviewersId,
        owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
        type: essayData?.type,
        isCopied: true,
      };
    } else {
      console.log('else 1');
      newEssay = {
        ...essayDetails,
        copiedFromId: copyFromEssay?.id,
        essaySubType: essayData?.essaySubType,
        essayName: essayData?.essayName,
        essayReferenceId: essayData?.essayReferenceId,
        sharedWith: reviewersId,
        owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
        applicationName: essayData?.applicationName || '',
        type: essayData?.type,
        isCopied: true,
      };
    }

    if (profileStore.profileLogin?.loggedInType === googleLogin) {
      if (copyFromEssay?.docs && !isParent) {
        const docId = copyFromEssay.docs?.split('/')[5];
        const copyDoc = await googleDocs.createCopyOfGoogleDoc(
          docId,
          googleDriveFolders.ESSAY,
          newEssay?.essayName
        );

        const googleDocData = await getDocDataInFormat(copyDoc?.id);
        await googleDocs.giveTeamMembersAccessToDocs(
          copyDoc?.id,
          essayData?.sharedWith?.map((reviewer) => reviewer.email)
        );

        essayId = await addStoryItem(
          {
            ...newEssay,
            isCopied: true,
            docs: copyDoc?.webViewLink,
            docInformation: {
              charCount: googleDocData?.charCount,
              content: googleDocData?.content,
              wordCount: googleDocData?.wordCount,
              reviewers: reviewersId,
            },
          },
          addStoryItemTypes.ESSAY
        );
      } else {
        //If the user is a parent, to create a shell of the essay
        if (isParent) {
          const newEssayParentData = { ...newEssay };
          //To remove the doc and docInformation from the parent data to create empty shell
          delete newEssayParentData['docs'];
          delete newEssayParentData['docInformation'];

          essayId = await addStoryItem(
            {
              ...newEssayParentData,
              createdBy: userProfile?.uid,
              isCopied: true,
            },
            addStoryItemTypes.ESSAY
          );
        } else {
          essayId = await addStoryItem(
            { ...newEssay, isCopied: true },
            addStoryItemTypes.ESSAY
          );
        }
      }
    } else {
      essayId = await addStoryItem({ ...newEssay }, addStoryItemTypes.ESSAY);
    }

    if (essayId && essayData?.sharedWith?.length > 0) {
      // Create chat channel for activity
      const channelMembers = addTeamUniversilyToChatParticipants([
        ...essayData?.sharedWith?.map((member) => member?.id),
        isParent ? selectedTeam?.studentUID : profileStore.profile.uid,
      ]);
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: channelMembers,
          topicName: 'Topic for ' + essayData?.essayName,
          utilityId: essayId,
          topicType: 'Essays',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }

    setSuccessSnackbar({
      noUndoAction: true,
      message: 'A copy of your essay has been created.',
    });
    essayStore.setIsActionSnackbar(true);
    essayStore.setAddOrEditEssayDocsId(essayId);
    essayStore.setAddOrEditEssayDocs(true);
  };

  const editEssayData = essayStore.editEssayId
    ? essayStore.essaysById[essayStore.editEssayId]
    : {};

  const onEditCoreEssay = async (essayData) => {
    if (profileStore.profileLogin?.loggedInType === googleLogin) {
      const essayDocId = editEssayData?.docs?.split('/')[5];
      await googleDocs.giveTeamMembersAccessToDocs(
        essayDocId,
        essayData?.sharedWith.map((reviewer) => reviewer.email)
      );
    }
    await updateStoryItem(
      {
        id: editEssayData?.id,
        essayCategory: essayData.essayCategory,
        sharedWith: (essayData?.sharedWith || []).map(
          (teamMember) => teamMember?.id
        ),
      },
      addStoryItemTypes.ESSAY
    );
    setSuccessSnackbar({
      noUndoAction: true,
      message: 'Your Essay details have been updated.',
    });
    essayStore.setIsActionSnackbar(true);
  };

  useEffect(() => {
    if (essayStore.essays) {
      setCoreEssays(
        essayStore.essays.filter(
          (essay) => essay.type === essayTypeForPlatform.coreEssay
        )
      );
    }
  }, [essayStore.essays, selectTemplate]);

  useEffect(() => {
    if (collegeIdToCreateNewEssay || essayIdToCreateNewEssay)
      setSelectedEssay(
        applicationStore.applications
          ?.find((app) => app.collegeId === collegeIdToCreateNewEssay)
          ?.essays?.find((essay) => essay?.id === essayIdToCreateNewEssay) ||
          essayStore.commonEssays?.find(
            (essay) => essay?.id === essayIdToCreateNewEssay
          )
      );
  }, [
    essayIdToCreateNewEssay,
    collegeIdToCreateNewEssay,
    applicationStore.applications,
    essayStore.commonEssays,
  ]);

  useEffect(() => {
    setCollegeName(
      applicationStore.applications?.find(
        (app) => app.collegeId === collegeIdToCreateNewEssay
      )?.collegeData?.collegeName
    );
    //eslint-disable-next-line
  }, [collegeIdToCreateNewEssay]);
  useEffect(() => {
    setPlatFormName(
      applicationTags?.find(
        (tag) => tag?.applicationPlatform === selectedEssay?.whereSubmitted
      )?.label
    );
    setWhereSubmitted(
      applicationTags?.find(
        (tag) => tag?.id.toString() === selectedEssay?.submittedTo?.toString()
      )?.applicationPlatform
    );
    setEssayApplicationName(
      applicationTags?.find(
        (tag) => tag?.id.toString() === selectedEssay?.submittedTo?.toString()
      )?.label
    );
  }, [selectedEssay]);
  const teamDataReviewersParentView = studentStore?.activeStudentReviewers.map(
    (reviewer) => ({
      email: reviewer.email,
      label: `${reviewer.firstName} ${reviewer.lastName}`,
      photoURL: reviewer.photoURL,
      id: reviewer.reviewerDocId,
    })
  );

  async function handleOnContinueApplicationEssay(essayData) {
    setDisableActionBtn(true);

    const { essayName, sharedWith = [] } = essayData;
    const teamMembersEmails = sharedWith.map((member) => member?.email);

    const reviewersIds = (essayData?.sharedWith || []).map(
      (teamMember) => teamMember?.id
    );

    let essayId;

    async function createAndShareDocument(
      documentDetails,
      isCoreEssay = false
    ) {
      const { docId, folder, docName, accessEmails, templateData } =
        documentDetails;
      let docResponse;
      if (isCoreEssay) {
        docResponse = await googleDocs.createCopyOfGoogleDoc(
          docId,
          folder,
          docName
        );
      } else {
        docResponse = await googleDocs.createGoogleDoc(
          essayName,
          folder,
          templateData
        );
      }
      await googleDocs.giveTeamMembersAccessToDocs(
        docResponse?.id,
        accessEmails
      );
      return docResponse;
    }

    async function finalizeEssayCreation(docResponse, additionalDetails) {
      const {
        docType,
        applicationName,
        collegeId,
        essaySubType,
        essayCategory,
      } = additionalDetails;
      const googleDocData = await getDocDataInFormat(docResponse?.id);
      const baseEssayDetails = {
        essayName,
        docs: docResponse?.webViewLink,
        type: docType,
        docInformation: {
          charCount: googleDocData?.charCount,
          content: googleDocData?.content,
          wordCount: googleDocData?.wordCount,
        },
        createdBy: studentStore?.selectedStudent?.uid,
        sharedWith: reviewersIds,
        owner: userProfile?.email,
      };

      if (essaySubType === essaySubTypeApplicationEssay.college) {
        baseEssayDetails.collegeId = collegeId;
      } else {
        baseEssayDetails.applicationName = applicationName;
      }

      baseEssayDetails.essaySubType = essaySubType;
      baseEssayDetails.essayReferenceId = essayData?.essayReferenceId;
      baseEssayDetails.essayCategory = essayCategory || '';
      return await addStoryItem(baseEssayDetails, addStoryItemTypes.ESSAY);
    }

    //runs if the user is not copying an application essay
    if (!essayStore.isCopyingApplicationEssay) {
      console.log('started !essayStore.isCopyingApplicationEssay');
      if (profileStore.profileLogin?.loggedInType === googleLogin) {
        console.log('started googleLogin');
        //runs if essay type is a core essay
        if (copyOption === essayTypeForPlatform.coreEssay) {
          console.log('started essayTypeForPlatform.coreEssay');
          const { selectedCoreEssayDocs, ...essayUtilData } = essayData;
          const docId = selectedCoreEssayDocs?.split('/')[5];
          console.log({ essayData });
          console.log('docId', docId);
          if (!isParent) {
            const docResponse = await createAndShareDocument(
              {
                docId,
                folder: googleDriveFolders.ESSAY,
                docName: essayUtilData?.essayName,
                accessEmails: teamMembersEmails,
              },
              true
            );
            essayId = await finalizeEssayCreation(docResponse, {
              ...essayUtilData,
              docType: ESSAY_TYPE_APPLICATION,
            });
          } else {
            console.log('started isParent');
            const parentEssayData = {
              essayName,
              type: ESSAY_TYPE_APPLICATION,
              sharedWith: reviewersIds,
              createdBy: studentStore?.selectedStudent?.uid,
              owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
              essayReferenceId: essayData?.essayReferenceId,
              essaySubType: essayData?.essaySubType,
              essayCategory: essayData?.essayCategory,
              isCopied: false,
              copiedFromId: essayData?.copiedFromId,
              collegeId: essayData?.collegeId,
            };
            if (essayData?.applicationName) {
              parentEssayData.applicationName = essayData?.applicationName;
            }
            essayId = await addStoryItem(
              parentEssayData,
              addStoryItemTypes.ESSAY
            );
          }
        } else {
          // Runs if creating from templates
          console.log('started !essayTypeForPlatform.coreEssay');
          const templateData = await googleDocs.googleDocData(
            templateStore.templateData || '',
            essayData.essayCategory || ''
          );
          if (!isParent) {
            const docResponse = await createAndShareDocument({
              folder: googleDriveFolders.ESSAY,
              accessEmails: teamMembersEmails,
              templateData,
            });
            essayId = await finalizeEssayCreation(docResponse, {
              ...essayData,
              docType: ESSAY_TYPE_APPLICATION,
            });
          } else {
            console.log('started isParent for template');
            const parentTemplateEssayData = {
              essayName,
              type: ESSAY_TYPE_APPLICATION,
              sharedWith: reviewersIds,
              createdBy: studentStore?.selectedStudent?.uid,
              owner: isParent ? selectedTeam?.studentEmail : userProfile?.email,
              essayCategory: essayData?.essayCategory,
            };
            if (essayData?.applicationName) {
              parentTemplateEssayData.applicationName =
                essayData?.applicationName;
            }
            if (essayData?.essayReferenceId) {
              parentTemplateEssayData.essayReferenceId =
                essayData?.essayReferenceId;
            }
            if (essayData?.essaySubType) {
              parentTemplateEssayData.essaySubType = essayData?.essaySubType;
            }
            if (essayData?.collegeId) {
              parentTemplateEssayData.collegeId = essayData?.collegeId;
            }
            essayId = await addStoryItem(
              parentTemplateEssayData,
              addStoryItemTypes.ESSAY
            );
          }
        }
      } else {
        console.log('started !googleLogin');
        essayId = await addStoryItem(
          {
            essayName,
            type: ESSAY_TYPE_APPLICATION,
            essayCategory: essayData.selectTemplateCategory || '',
            sharedWith: reviewersIds,
            owner: userProfile?.email,
            applicationName: essayData?.applicationName,
          },
          addStoryItemTypes.ESSAY
        );
      }
    } else {
      console.log('started essayStore.isCopyingApplicationEssay');
      copyApplicationEssay(essayData);
    }

    // create chat room for the essay
    if (essayId) {
      // Create chat channel for activity
      const channelMembers = addTeamUniversilyToChatParticipants([
        ...essayData?.sharedWith?.map((member) => member?.id),
        isParent ? selectedTeam?.studentUID : profileStore.profile.uid,
      ]);
      await addStoryItem(
        {
          isTopic: true,
          archivedBy: [],
          chatParticipants: channelMembers,
          topicName: 'Topic for ' + essayName,
          utilityId: essayId,
          topicType: 'Essays',
        },
        addStoryItemTypes.CHAT_ROOM
      );
    }

    showSuccessSnackbar();
    finalizeEssayProcess(essayId);
  }

  function showSuccessSnackbar() {
    setSuccessSnackbar({
      noUndoAction: true,
      message: 'Your Application Essay has been added',
    });
    essayStore.setIsActionSnackbar(true);
  }

  function finalizeEssayProcess(essayId) {
    essayStore.setAddOrEditEssayDocsId(essayId);
    essayStore.setCurrentSelectedEssay(essayId);
    essayStore.setAddOrEditEssayDocs(true);
    setDisableActionBtn(false);
    setSelectTemplate({});
    setCopyOption(essayTypeForPlatform.coreEssay);
  }

  const collaborators = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== selectedTeam?.studentEmail)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
        }))
    : [];

  return (
    <>
      {isAddingEssayForApplication && (
        <AddEssayApplicationDialog
          open={isAddingEssayForApplication}
          onCancel={() => setIsAddingEssayForApplication(false)}
          selectedEssay={selectedEssay}
          collegeName={
            (selectedEssay?.essayType === 'Common Essay'
              ? platFormName
              : collegeName) || essayApplicationName
          }
          collegeId={collegeIdToCreateNewEssay}
          reviewers={isParent ? teamDataReviewersParentView : teamDataReviewers}
          whereSubmittedToCreateNewEssay={essayApplicationName}
          whereSubmitted={whereSubmitted}
          isEssayPage={true}
          isParent={isParent}
        />
      )}
      <AddCollegeDialog
        title="Add College"
        continueText="Add"
        open={essayStore.isAddCollege}
        onCancel={() => essayStore.setIsAddCollege(false)}
        collegeList={adminStore.collegeListData}
        onContinue={async (data) => {
          const isCollegeAdded = applicationStore.applications?.some(
            (app) => app.collegeId === data?.collegeId
          );
          if (isCollegeAdded) {
            setSuccessSnackbar({
              open: true,
              message: 'This College is already added',
              noUndoAction: true,
            });
          } else {
            const collaborators = teamStore.selectedTeam?.teamMembers?.map(
              (member) => member?.uid
            );
            const channelMembers =
              addTeamUniversilyToChatParticipants(collaborators);
            const sharedWith = channelMembers.filter(
              (member) => member !== teamStore.selectedTeam.studentUID
            );
            const collegeData = {
              ...data,
              sharedWith,
              owner: teamStore.selectedTeam?.studentEmail,
              createdBy: profileStore.profile?.uid,
            };

            const collegeId = await addStoryItem(
              collegeData,
              addStoryItemTypes.APPLICATIONS
            );
            if (collegeId) {
              await addStoryItem(
                {
                  isTopic: true,
                  archivedBy: [],
                  chatParticipants: channelMembers,
                  topicName: 'Topic for College',
                  utilityId: collegeId,
                  topicType: 'Applications',
                },
                addStoryItemTypes.CHAT_ROOM
              );
            }
            setSuccessSnackbar({
              open: true,
              message: 'A new college has been added',
              noUndoAction: true,
            });
          }
          essayStore.setIsActionSnackbar(true);
          essayStore.setIsAddCollege(false);
        }}
      />
      <SuccessSnackbar
        message={successSnackbar.message}
        open={essayStore.isActionSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          essayStore.setDeleteEssayId(null);
          essayStore.setIsActionSnackbar(false);
          setSuccessSnackbar({ message: '', noUndoAction: false });
        }}
        actionName={!successSnackbar.noUndoAction && 'Undo'}
        onAction={async () => {
          await undoDeleteEssay();
        }}
      />
      <AddCoreEssayDialog
        open={essayStore.isAddingCoreEssay || essayStore.isEditingEssay}
        title={essayStore.isAddingCoreEssay ? 'Add Essay' : 'Edit Essay'}
        onContinue={async (essayData) => {
          setDisableActionBtn(true);
          if (essayStore.isAddingCoreEssay) {
            await onAddCoreEssay(essayData);
          } else if (essayStore.isEditingEssay) {
            await onEditCoreEssay(essayData);
          }
          setDisableActionBtn(false);
          closeDialog();
        }}
        collaborators={filterUniAndTeamUniversily(collaborators)}
        disabled={disableActionBtn}
        onCancel={() => {
          closeDialog();
          setDisableActionBtn(false);
        }}
        maxWidth="md"
        isAdding={essayStore.isAddingCoreEssay}
        editEssayData={essayStore.isEditingEssay ? editEssayData : {}}
        isEditingEssay={essayStore.isEditingEssay}
      />
      <DeleteEssayDialog
        open={essayStore.isDeletingEssay}
        maxWidth="xs"
        onCancel={() => {
          essayStore.setIsDeletingEssay(false);
          setDisableActionBtn(false);
        }}
        disabled={disableActionBtn}
        onContinue={async () => {
          setDisableActionBtn(true);
          await onDelete();
          essayStore.setIsDeletingEssay(false);
          if (essayStore.addOrEditEssayDocs) {
            essayStore.setAddOrEditEssayDocsId(null);
            essayStore.setAddOrEditEssayDocs(false);
          }
          setDisableActionBtn(false);
        }}
      />
      <CopyCoreEssayDialog
        title="Copy as Core Essay"
        open={essayStore.isCopyingEssay}
        onCancel={() => {
          essayStore.setCopyEssayId(null);
          essayStore.setIsCopyingEssay(false);
          setDisableActionBtn(false);
        }}
        disabled={disableActionBtn}
        onContinue={async (essayData) => {
          setDisableActionBtn(true);
          await copyCoreEssay(essayData);
          essayStore.setCopyEssayId(null);
          essayStore.setIsCopyingEssay(false);
          setDisableActionBtn(false);
        }}
        essayName={
          essayStore.isCopyingEssay &&
          `Copy of ${essayStore.essaysById[essayStore.copyEssayId]?.essayName}`
        }
        teamDataReviewers={filterUniAndTeamUniversily(teamDataReviewers)}
      />
      <AddApplicationEssayDialog
        open={essayStore.isAddingEssay || essayStore.isCopyingApplicationEssay}
        title={
          essayStore.isCopyingApplicationEssay && !essayStore.isAddingEssay
            ? 'Copy as Application Essay'
            : essayStore.isAddingEssay && !essayStore.isCopyingApplicationEssay
            ? 'Add Application Essay'
            : 'Edit Application Essay'
        }
        maxWidth="md"
        disabled={disableActionBtn}
        addProcessStep={addProcessStep}
        setAddProcessStep={setAddProcessStep}
        collaborators={filterUniAndTeamUniversily(collaborators)}
        copyOption={copyOption}
        setCopyOption={setCopyOption}
        coreEssays={coreEssays || []}
        onCancel={() => {
          closeDialog();
          setDisableActionBtn(false);
        }}
        isAdding={essayStore.isAddingEssay}
        collegeList={applicationStore.applications}
        selectTemplate={selectTemplate}
        setSelectTemplate={setSelectTemplate}
        onContinue={async (essayData) => {
          await handleOnContinueApplicationEssay(essayData);
        }}
        commonEssays={essayStore.commonEssays}
      />
    </>
  );
};
