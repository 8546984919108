import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { axiosPost } from '../firebase/axios';
import useProfileStore from '../stores/ProfileStore';
import { userTypeKeywords } from '../constants/keywords';
import * as ROUTES from '../navigation/constants';
import Loader from '../components/shared/Loader';
import { ApiRoutes } from '../firebase/apis';
import { useAuth } from '../hooks';
import useTeamStore from '../stores/TeamStore';

const GuestGuard = ({ children }) => {
  const { user, isLoading } = useAuth();
  const isOneStudentExists = user?.teams?.length > 0;
  const [searchParams] = useSearchParams();
  const setShowOnboarding = useProfileStore((state) => state.setShowOnboarding);
  const setSnackBarStatus = useProfileStore((state) => state.setSnackBarStatus);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTeam = useTeamStore((state) => state.selectedTeam);
  const url = new URL(window.location.href);
  const team = url.searchParams.get('team') || '';
  const inviteeMail = url.searchParams.get('inviteeMail') || '';
  useEffect(() => {
    const emailParam =
      searchParams.get('reviewerMail') || searchParams.get('studentMail');
    if (location.pathname === ROUTES.SIGN_UP && emailParam) {
      const getUserByEmailAndRedirect = async () => {
        const { data } = await axiosPost(ApiRoutes.CHECK_IF_USER_EXISTS, {
          email: emailParam,
        });

        if (data?.email) {
          navigate(ROUTES.ACCEPT_TEAM_INVITE + location.search);
        } else {
          if (user?.profileType) {
            setSnackBarStatus({
              open: true,
              message: `The invitation was sent for ${emailParam} and you are logged in as ${user.email}.`,
            });
          }
          navigate(ROUTES.SIGN_UP + location.search);
        }
      };
      getUserByEmailAndRedirect();
    }
  }, [location.pathname, user?.email]);

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  // When user registered themselves and clicked on the old invitation link (for unregistered user) then redirect them to the accept invitation page
  if (team && inviteeMail && url.pathname === ROUTES.SIGN_UP && user?.email) {
    return (
      <Navigate
        to={`${ROUTES.ACCEPT_TEAM_INVITE}?team=${team}&inviteeMail=${inviteeMail}`}
      />
    );
  }

  if (user) {
    if (
      user?.profileType !== userTypeKeywords.STUDENT &&
      !user?.isOnboardingCompleted
    ) {
      // Show onboarding dialog
      setShowOnboarding(true);
      return <Navigate to={ROUTES.MY_TEAM} />;
    }

    switch (user.profileType) {
      case userTypeKeywords.STUDENT:
        return <Navigate to={ROUTES.MY_HUB} />;
      case userTypeKeywords.ADMINISTRATOR:
        return <Navigate to={ROUTES.USER_LOGIN_DETAILS} />;
      default:
        const userTypesWithHubAccess = [
          userTypeKeywords.FAMILY,
          userTypeKeywords.PRIVATE_COUNSELOR,
          userTypeKeywords.SCHOOL_COUNSELOR,
          userTypeKeywords.TEACHER,
          userTypeKeywords.UNIVERSILY,
        ];
        if (
          userTypesWithHubAccess.includes(user?.profileType) &&
          isOneStudentExists
        ) {
          return selectedTeam?.id ? (
            <Navigate to={`/${selectedTeam?.id}/${ROUTES.MY_HUB}`} />
          ) : (
            <Navigate to={ROUTES.MY_HOME} />
          );
        } else {
          return <Navigate to={ROUTES.MY_HOME} />;
        }
    }
  } else {
    return <>{children}</>;
  }
};

export default GuestGuard;
