import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { documentExists } from '../../firebase/services/auth';
import * as ROUTES from '../../navigation/constants';
import { updateDocument } from '../../firebase/services/updateServices';
import { INVITE_STATUS } from '../../utils/utlityTypes';
import { PROFILE, TEAMS } from '../../firebase/constants';
import { CircularProgress } from '@mui/material';

export const AcceptRequest = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const teamId = searchParams.get('team');
  const requestedMember = searchParams.get('requestedMember');
  const accepted = searchParams.get('accepted');
  console.log({ teamId, requestedMember, accepted });

  useEffect(() => {
    async function handleAcceptRequest() {
      const teamData = await documentExists('teams', 'id', teamId);
      const requestedMemberData = await documentExists(
        'profile',
        'uid',
        requestedMember
      );

      //0 - Check if accepted is true
      if (accepted !== 'true') {
        console.log('User was not accepted to the team');
        // Delete the user from the team
        const updatedMembersList = teamData?.data[0]?.teamMembers?.filter(
          (member) => member?.uid !== requestedMember
        );
        await updateDocument(
          'teams',
          'id',
          teamId,
          'teamMembers',
          updatedMembersList,
          true
        );
        await updateDocument(
          'profile',
          'uid',
          requestedMember,
          'teams',
          [],
          true
        );
        // TODO (RUTUJA): Show a snackbar message The user was not accepted to the team

        navigate(ROUTES.MY_TEAM);
        return;
      }

      console.log("LANDED HERE")

      //1 - Check if the user is already a member of the team
      const isMember = teamData?.data[0]?.teamMembers?.some(
        (member) => member?.uid === requestedMember
      );
      const requestedMemberStatus = teamData?.data[0]?.teamMembers?.find(
        (member) => member?.uid === requestedMember
      )?.invite_status;

      //2 - If the user is already a member of the team, navigate to the team page
      if (isMember && requestedMemberStatus === INVITE_STATUS.JOINED) {
        navigate(ROUTES.MY_TEAM);
        // TODO (RUTUJA): Show a snackbar message
        return;
      }
      console.log({ isMember, requestedMemberStatus });
      //3 - If the user is not a member of the team, add the user to the team and navigate to the team
      if (
        isMember &&
        requestedMemberStatus === INVITE_STATUS.REQUESTED_TO_JOIN
      ) {
        console.log('User is not a member of the team');
        const updatedMembersList = teamData?.data[0]?.teamMembers?.map(
          (member) => {
            if (member?.uid === requestedMember)
              return { ...member, invite_status: INVITE_STATUS.JOINED };
            return member;
          }
        );

        await updateDocument(
          TEAMS,
          'id',
          teamId,
          'teamMembers',
          updatedMembersList,
          true
        );

        const updatedTeamsList = requestedMemberData?.data[0]?.teams?.map(
          (team) => {
            if (team?.teamId === teamId)
              return { ...team, status: INVITE_STATUS.JOINED };
            return team;
          }
        );
        await updateDocument(
          PROFILE,
          'uid',
          requestedMember,
          'teams',
          updatedTeamsList,
          true
        );
        navigate(ROUTES.MY_TEAM);
        // TODO (RUTUJA): Show a snackbar message
      }
    }
    handleAcceptRequest();
  }, [accepted, requestedMember, teamId, navigate, searchParams]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
        You have accepted the team membership request from!
        </h1>
        <p className="text-lg text-gray-600 mb-4">
          Please wait while we redirect you to your home page.
        </p>
        <div className="flex justify-center">
          <CircularProgress color="primary" />
        </div>
      </div>
    </div>
  );;
};
