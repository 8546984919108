import { useEffect, useMemo, useState } from 'react';
import {
  addStoryItemTypes,
  CIO_SHARE_ITEM_TYPES,
  firebaseDataType,
} from '../constants/other';
import {
  ACTIVITIES,
  ACTIVITY_TYPES,
  COMMON_ESSAYS,
  APPLICATION_TEMPLATES,
  COLLEGES_LIST,
  COURSE,
  ESSAY,
  HONORS,
  HONOR_TYPES,
  NICHE,
  PROFILE,
  PROFILE_LOGIN,
  REFERRAL,
  TEST_SCORES,
  TEST_SUBJECTS,
  TEST_TARGETS,
  TEST_TYPES,
  UNIVERSILY_TEMPLATES,
  APPLICATIONS,
  GPA_SCORES,
  TEAMS,
} from '../firebase/constants';
import {
  getProfileByUserId,
  getProfileDataByEmail,
} from '../firebase/services/user';
import { useStoryItemListener } from '../hooks';
import useMyTeamStore from '../stores/MyTeamStore';
import useProfileStore from '../stores/ProfileStore';
import ChatWindowContainer from './ChatWindow.container';
import TeamMemberContainer from './TeamMember.container';
import { userTypeKeywords } from '../constants/keywords';
import MyAccountContainer from './MyAccount.container';
import useInviteAFriendStore from '../stores/InviteAFriendStore';
import { ReferAFriendContainer } from './ReferAFriend.container';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import useCourseStore from '../stores/CourseStore';
import useActivityStore from '../stores/ActivityStore';
import useNicheStore from '../stores/NicheStore';
import useHonorStore from '../stores/HonorStore';
import useTestStore from '../stores/TestStore';
import { getApplicationCompletionValue, sortData } from '../utils/helper';
import useTemplateStore from '../stores/TemplateStore';
import { MyEssayContainer } from './MyEssay.container';
import useEssayStore from '../stores/EssayStore';
import AdminContainer from './Admin.container';
import useAdminStore from '../stores/AdminStore';
import useApplicationStore from '../stores/ApplicationStore';
import { MyApplicationContainer } from './MyApplication.container';
import useMyStudentStore from '../stores/MyStudentStore';
import MyStudentContainer from './MyStudent.container';
import { useGPAStore } from '../stores/GPAStore';
import { fetchDocuments } from '../firebase/services/getServices';
import {
  GlobalSnackbar,
  openSnackbar,
} from '../components/shared/GlobalSnackbar/GlobalSnackbar';
import useTeamStore from '../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import { useParams } from 'react-router-dom';
import { ShareUtility } from '../components/myHub/ShareUtility';
import {
  filterUniAndTeamUniversily,
  INVITE_STATUS,
  TEAM_UNIVERSILY_DETAILS,
} from '../utils/utlityTypes';
import { axiosPost } from '../firebase/axios';
import { ApiRoutes } from '../firebase/apis';
import useMyHubStore from '../stores/MyHubStore';
import useTeams from '../hooks/useTeam';

const AppContainer = () => {
  const courseStore = useCourseStore(
    useShallow((state) => ({
      setCourses: state.setCourses,
      setIsFetching: state.setIsFetching,
    }))
  );
  const activityStore = useActivityStore(
    useShallow((state) => ({
      setActivities: state.setActivities,
      setIsFetchingActivities: state.setIsFetchingActivities,
      setActivityTypes: state.setActivityTypes,
      setDeleteActivityId: state.setDeleteActivityId,
    }))
  );
  const honorStore = useHonorStore(
    useShallow((state) => ({
      setHonors: state.setHonors,
      setHonorTypes: state.setHonorTypes,
      setDeleteHonorId: state.setDeleteHonorId,
      setIsFetchingHonors: state.setIsFetchingHonors,
    }))
  );
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      setNiches: state.setNiches,
    }))
  );
  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({
      setMyTeam: state.setMyTeam,
      setIsFetchingTeamData: state.setIsFetchingTeamData,
    }))
  );
  const myStudentStore = useMyStudentStore(
    useShallow((state) => ({
      setMyStudents: state.setMyStudents,
      setIsFetching: state.setIsFetching,
    }))
  );
  const testStore = useTestStore(
    useShallow((state) => ({
      setTestTypes: state.setTestTypes,
      setTestScores: state.setTestScores,
      setTestTargets: state.setTestTargets,
      setTestSubjectsSAT: state.setTestSubjectsSAT,
      setTestSubjectsACT: state.setTestSubjectsACT,
    }))
  );
  const gpaStore = useGPAStore(
    useShallow((state) => ({
      setGpaScores: state.setGpaScores,
      setIsFetchingScores: state.setIsFetchingScores,
    }))
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      profile: state.profile,
      setProfile: state.setProfile,
      setHasPremiumAccess: state.setHasPremiumAccess,
      setCanCreateNewUtilities: state.setCanCreateNewUtilities,
      setProfileLogin: state.setProfileLogin,
    }))
  );

  const templateStore = useTemplateStore(
    useShallow((state) => ({
      setTemplateData: state.setTemplateData,
    }))
  );
  const essayStore = useEssayStore(
    useShallow((state) => ({
      setCommonEssays: state.setCommonEssays,
      setEssays: state.setEssays,
      setIsFetchingEssays: state.setIsFetchingEssays,
    }))
  );
  const applicationStore = useApplicationStore(
    useShallow((state) => ({
      setApplications: state.setApplications,
    }))
  );
  const inviteStore = useInviteAFriendStore(
    useShallow((state) => ({
      setInvitedMembers: state.setInvitedMembers,
      setIsFetchingInviteAFriendData: state.setIsFetchingInviteAFriendData,
    }))
  );
  const [owner, setOwner] = useState('');
  const adminStore = useAdminStore(
    useShallow((state) => ({
      setApplicationRequirements: state.setApplicationRequirements,
      setCollegeDetails: state.setCollegeDetails,
      setCollegeListData: state.setCollegeListData,
      collegeListData: state.collegeListData,
    }))
  );
  const { teamID } = useParams();

  const myHubStore = useMyHubStore(
    useShallow((state) => ({
      isShareUtility: state.isShareUtility,
      utilityId: state.utilityId,
      setIsShareUtility: state.setIsShareUtility,
      setUtilityId: state.setUtilityId,
      setUtilityType: state.setUtilityType,
      utilityType: state.utilityType,
      setUtilityName: state.setUtilityName,
      utilityName: state.utilityName,
    }))
  );

  const teamStore = useTeamStore(
    useShallow((state) => ({
      setTeams: state.setTeams,
      selectedTeam: state.selectedTeam,
    }))
  );

  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
    profileStore?.profile?.profileType !== userTypeKeywords.ADMINISTRATOR;

  useEffect(() => {
    const email = isParent
      ? teamStore?.selectedTeam?.studentEmail
      : profileStore.profile?.email;
    //setFetchId(id);
    setOwner(email);
    // eslint-disable-next-line
  }, [teamStore.selectedTeam?.studentEmail, profileStore.profile?.uid]);

  // Check for premium access and can create new utilities and set it to profile store
  useEffect(() => {
    const currentProfile = isParent
      ? teamStore?.selectedTeam
      : profileStore.profile;
    profileStore?.setHasPremiumAccess(currentProfile);
    profileStore?.setCanCreateNewUtilities(currentProfile);
    setOwner(isParent ? teamStore?.selectedTeam?.studentEmail : profileStore.profile?.email);
  }, [
    teamStore.selectedTeam?.studentEmail,
    profileStore.profile?.uid,
    isParent,
    profileStore,
    teamStore,
  ]);
  const { data: nichesData } = useStoryItemListener(
    NICHE,
    firebaseDataType.METADATA,
    [
      {
        property: 'owner',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: owner,
      },
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ],
    owner
  );

  // eslint-disable-next-line no-unused-vars
  const { data: commonEssayData, isFetching: isFetchingCommonEssay } =
    useStoryItemListener(COMMON_ESSAYS, firebaseDataType.METADATA, [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]);

  const { data: googleTemplateData } = useStoryItemListener(
    UNIVERSILY_TEMPLATES,
    firebaseDataType.METADATA
  );

  // Filter items only shared with current logged in collaborator
  const checkReviewer = (dataList) => {
    // Since Team Universily can see all data, return all data
    if(profileStore.profile?.uid === TEAM_UNIVERSILY_DETAILS.uid) {
      return dataList;
    }
    // Filter data based on sharedWith property
    const dataItems = dataList?.filter((item) =>
      item?.sharedWith?.includes(profileStore.profile?.uid)
    );
    return dataItems;
  };

  const { data: activitiesData, isFetching: isFetchingActivities } =
    useStoryItemListener(
      ACTIVITIES,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );
  const { data: honorsData, isFetching: isFetchingHonors } =
    useStoryItemListener(
      HONORS,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  const { data: coursesData, isFetching } = useStoryItemListener(
    COURSE,
    firebaseDataType.METADATA,
    [
      {
        property: 'owner',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: owner,
      },
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ],
    owner
  );

  const { data: testTypeData, isFetching: isFetchingTestType } =
    useStoryItemListener(TEST_TYPES, firebaseDataType.METADATA, [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]);

  const { data: essaysData, isFetching: isFetchingEssays } =
    useStoryItemListener(
      ESSAY,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  const { data: testScoresData, isFetching: isFetchingTestScores } =
    useStoryItemListener(
      TEST_SCORES,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  const { data: gpaScores, isFetching: isFetchingGPAScores } =
    useStoryItemListener(
      GPA_SCORES,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
      ],
      owner
    );

  const { data: testTargetData, isFetching: isFetchingTestTarget } =
    useStoryItemListener(
      TEST_TARGETS,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  const { data: activityTypesData } = useStoryItemListener(
    ACTIVITY_TYPES,
    firebaseDataType.METADATA
  );

  const { data: honorTypesData } = useStoryItemListener(
    HONOR_TYPES,
    firebaseDataType.METADATA
  );

  const addUtilityType = (data, type) => {
    return data?.map((item) => {
      return { ...item, utilityType: type };
    });
  };

  const addApplicationProgressValue = (utilData) => {
    if (utilData?.length > 0) {
      return utilData?.map((item) => {
        if (item?.markedAsSubmitted) {
          return {
            ...item,
            applicationProgress: 100,
          };
        } else {
          return {
            ...item,
            applicationProgress: getApplicationCompletionValue(item),
          };
        }
      });
    }
  };

  useEffect(() => {
    const coursesFilteredData = isParent
      ? checkReviewer(coursesData)
      : coursesData;
    const utilityData = addUtilityType(
      coursesFilteredData,
      addStoryItemTypes.COURSE
    );
    const sortedData = sortData(utilityData);
    courseStore.setCourses(sortedData || []);
    courseStore.setIsFetching(isFetching || false);
    // eslint-disable-next-line
  }, [coursesData, isFetching]);

  useEffect(() => {
    templateStore.setTemplateData(googleTemplateData);
    // eslint-disable-next-line
  }, [googleTemplateData]);

  useEffect(() => {
    const filteredNicheData = isParent ? checkReviewer(nichesData) : nichesData;
    nicheStore.setNiches(filteredNicheData || []);
    // eslint-disable-next-line
  }, [nichesData]);

  useEffect(() => {
    testStore.setTestTypes(testTypeData || []);
    // eslint-disable-next-line
  }, [testTypeData, isFetchingTestType]);

  useEffect(() => {
    const utilityData = addUtilityType(
      testScoresData,
      addStoryItemTypes.TEST_SCORE
    );
    const sortedData = sortData(utilityData);
    testStore.setTestScores(sortedData);
    // eslint-disable-next-line
  }, [testScoresData, isFetchingTestScores]);

  useEffect(() => {
    gpaStore.setGpaScores(gpaScores || []);
    gpaStore.setIsFetchingScores(isFetchingGPAScores);
    // eslint-disable-next-line
  }, [gpaScores, isFetchingGPAScores]);

  useEffect(() => {
    testStore.setTestTargets(testTargetData || []);
    // eslint-disable-next-line
  }, [testTargetData, isFetchingTestTarget]);

  useEffect(() => {
    essayStore.setCommonEssays(commonEssayData);
    //eslint-disable-next-line
  }, [commonEssayData]);

  const memoizedTeamList = useMemo(() => {
    return profileStore.profile?.teams?.map((team) => team?.teamId);
  }, [profileStore.profile?.teams]);

  // Listen to team data
  const { teams: teamData, isFetchingTeamData } = useTeams(memoizedTeamList);

  const { data: profileData, isFetching: isFetchingProfileData } =
    useStoryItemListener(PROFILE);

  const { data: profileLoginData, isFetching: isFetchingProfileLoginData } =
    useStoryItemListener(PROFILE_LOGIN);

  useEffect(() => {
    if(profileData && profileData[0]){
      profileStore.setProfile(profileData && profileData[0]);
    }

    // eslint-disable-next-line
  }, [profileData, isFetchingProfileData]);

  useEffect(() => {
    profileStore.setProfileLogin(profileLoginData && profileLoginData[0]);
    // eslint-disable-next-line
  }, [profileLoginData, isFetchingProfileLoginData]);

  useEffect(() => {
    const essayList = isParent ? checkReviewer(essaysData) : essaysData;
    const utilData = addUtilityType(essayList, addStoryItemTypes.ESSAY);
    essayStore.setEssays(sortData(utilData) || []);
    essayStore.setIsFetchingEssays(isFetchingEssays);
    //eslint-disable-next-line
  }, [essaysData, isFetchingEssays]);

  const { data: referralData, isFetching: isFetchingInviteAFriendData } =
    useStoryItemListener(REFERRAL);

  const getProfileDataForReferral = async () => {
    const teamMemberProfileData = referralData?.map((referral) => {
      return {
        ...referral,
        isRegistered: referral?.isRegistered ? referral?.isRegistered : false,
      };
    });
    inviteStore.setInvitedMembers(teamMemberProfileData || []);
    inviteStore.setIsFetchingInviteAFriendData(
      isFetchingInviteAFriendData || false
    );
  };

  const { data: testSubjectSAT, isFetching: isFetchingTestSubjectSAT } =
    useStoryItemListener(
      testTypeData
        ? `${TEST_TYPES}/${testTypeData[0]?.id}/${TEST_SUBJECTS}`
        : '',
      firebaseDataType.METADATA
    );

  const { data: testSubjectACT, isFetching: isFetchingTestSubjectACT } =
    useStoryItemListener(
      testTypeData
        ? `${TEST_TYPES}/${testTypeData[1]?.id}/${TEST_SUBJECTS}`
        : '',
      firebaseDataType.METADATA
    );

  useEffect(() => {
    const SATSubjectsSorted = testSubjectSAT?.sort(
      (sub1, sub2) => sub1.order - sub2.order
    );
    testStore.setTestSubjectsSAT(SATSubjectsSorted);
    // eslint-disable-next-line
  }, [testSubjectSAT, isFetchingTestSubjectSAT]);

  useEffect(() => {
    const ACTSubjectsSorted = testSubjectACT?.sort(
      (sub1, sub2) => sub1.order - sub2.order
    );
    testStore.setTestSubjectsACT(ACTSubjectsSorted);
    // eslint-disable-next-line
  }, [testSubjectACT, isFetchingTestSubjectACT]);

  useEffect(() => {
    getProfileDataForReferral();
    // eslint-disable-next-line
  }, [referralData, isFetchingInviteAFriendData]);

  const fetchDataForReviewer = async () => {
    const loggedInUserTeams = profileStore?.profile?.teams;

    if (loggedInUserTeams?.length > 0) {
      // Fetch logged-in user's team data
      const teamsData = await fetchDocuments(
        TEAMS,
        loggedInUserTeams,
        'teamId',
        'id'
      );

      // Fetch team members profile data for the first team
      const teamMembersProfileData = await fetchDocuments(
        PROFILE,
        teamsData[0]?.teamMembers,
        'uid',
        'uid'
      );

      // Map team members to profile data
      const teamMembers = teamsData[0]?.teamMembers.map((teamMember) => {
        const profileData = teamMembersProfileData.find(
          (profile) => profile?.uid === teamMember?.uid
        );
        return profileData
          ? {
              ...teamMember,
              profileId: profileData?.id,
              profileUserId: profileData?.uid,
              photoURL: profileData?.photoURL,
              profileDate: profileData?.profileDate,
              name: profileData?.profileName,
              firstName: profileData?.firstName,
              lastName: profileData?.lastName,
            }
          : { ...teamMember };
      });

      // Set team data in stores
      myTeamStore.setMyTeam(teamMembers || []);
      myTeamStore.setIsFetchingTeamData(false);

      // Set student data
      myStudentStore.setMyStudents(teamsData || []);
      myStudentStore.setIsFetching(false);

      // Process team data with student license
      const teamsDataWithStudentLicense = await Promise.all(
        teamsData.map(async (team) => {
          const studentProfile = await getProfileDataByEmail(
            team?.studentEmail
          );
          return {
            ...team,
            licenseData: studentProfile?.licenseData,
            studentFirstName: studentProfile?.firstName,
            studentLastName: studentProfile?.lastName,
            studentPhotoURL: studentProfile?.photoURL,
            studentUID: studentProfile?.uid,
            studentGrade: studentProfile?.grade,
            studentSchoolName: studentProfile?.schoolName,
            studentAddress: studentProfile?.address,
            studentProfileType: studentProfile?.profileType,
          };
        })
      );

      teamStore.setTeams(teamsDataWithStudentLicense || []);

      // Additional logic for setting selected team (if not a parent)
      if (!isParent) {
        const selectedTeam = teamsDataWithStudentLicense.find(
          (team) => team?.studentEmail === profileStore?.profile?.email
        );
        const collabProfiles = await Promise.all(
          selectedTeam?.teamMembers?.map(async (member) => {
            let memberDetails = { ...member };
            if (member?.uid) {
              const memberProfile = await getProfileByUserId(member?.uid);
              if (memberProfile.length > 0) {
                const { photoURL, firstName, lastName } = memberProfile[0];
                memberDetails = {
                  ...memberDetails,
                  photoURL,
                  firstName,
                  lastName,
                };
              }
            }
            return memberDetails;
          })
        );

        teamStore.setSelectedTeam({
          ...selectedTeam,
          teamMembers: collabProfiles,
        });
      } else if (teamID) {
        const selectedTeam = teamsDataWithStudentLicense.find(
          (team) => team.id === teamID
        );
        if (selectedTeam) {
          const collabProfiles = await Promise.all(
            selectedTeam?.teamMembers?.map(async (member) => {
              let memberDetails = { ...member };
              if (member?.uid) {
                const memberProfile = await getProfileByUserId(member?.uid);
                const { photoURL, firstName, lastName } = memberProfile[0];
                memberDetails = {
                  ...memberDetails,
                  photoURL,
                  firstName,
                  lastName,
                };
              }
              return memberDetails;
            })
          );

          teamStore.setSelectedTeam({
            ...selectedTeam,
            teamMembers: collabProfiles,
          });
        }
      }
    }
  };

  // For initial load, to fetch team data
  useEffect(() => {
    const fetchData = async () => {
      if (!isFetchingTeamData && teamData) {
        await fetchDataForReviewer();
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To fetch team data when team data is updated
  useEffect(() => {
    const fetchData = async () => {
      if (!isFetchingTeamData && teamData.length > 0) {
        await fetchDataForReviewer();
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, isFetchingTeamData]);

  useEffect(() => {
    const activitiesFilteredData = isParent
      ? checkReviewer(activitiesData)
      : activitiesData;
    const utilityData = addUtilityType(
      activitiesFilteredData,
      addStoryItemTypes.ACTIVITY
    );
    const activityData = sortData(utilityData);
    activityStore.setActivities(activityData || []);
    activityStore.setDeleteActivityId(null);
    activityStore.setIsFetchingActivities(isFetchingActivities);
    // eslint-disable-next-line
  }, [activitiesData, isFetchingActivities]);

  useEffect(() => {
    activityStore.setActivityTypes(activityTypesData || []);
    // eslint-disable-next-line
  }, [activityTypesData]);

  useEffect(() => {
    const honorsFilteredData = isParent
      ? checkReviewer(honorsData)
      : honorsData;
    const utilityData = addUtilityType(
      honorsFilteredData,
      addStoryItemTypes.HONOR
    );
    const honorData = sortData(utilityData);
    honorStore.setHonors(honorData || []);
    honorStore.setDeleteHonorId(null);
    honorStore.setIsFetchingHonors(isFetchingHonors);
    // eslint-disable-next-line
  }, [honorsData, isFetchingHonors]);

  useEffect(() => {
    honorStore.setHonorTypes(honorTypesData || []);
    // eslint-disable-next-line
  }, [honorTypesData]);

  const { data: applicationDetail } = useStoryItemListener(
    APPLICATION_TEMPLATES,
    firebaseDataType.METADATA,
    [
      {
        property: 'deleteDocument',
        operator: firebaseQueryOperators.EQUAL_TO,
        value: false,
      },
    ]
  );

  const { data: collegeList } = useStoryItemListener(
    COLLEGES_LIST,
    firebaseDataType.METADATA
  );

  useEffect(() => {
    adminStore.setApplicationRequirements(applicationDetail);
    // eslint-disable-next-line
  }, [applicationDetail]);

  useEffect(() => {
    adminStore.setCollegeDetails(collegeList);
    // eslint-disable-next-line
  }, [collegeList]);

  useEffect(() => {
    let data = [];
    const collegeListData = sortData(collegeList);

    collegeListData?.forEach((college) => {
      let i = 0;
      applicationDetail?.forEach((item) => {
        if (
          (item?.collegeId === college?.id ||
            item?.collegeData?.id === college?.id) &&
          data.every((value) => value?.collegeData?.id !== college?.id)
        ) {
          i = 1;
          data.push({
            ...item,
            collegeApplicationPortal: college?.collegeApplicationPortal || '',
            collegeId: item?.collegeId || college?.id,
            collegeData: {
              id: college?.id,
              collegeApplicationPortal: college?.collegeApplicationPortal || '',
              createdAt: college?.createdAt || '',
              updatedAt: college?.updatedAt || '',
              deletedAt: college?.deletedAt || '',
              collegeId: college?.id || '',
              collegeUrl: college?.collegeUrl || '',
              collegeLogoURL: college?.collegeLogoURL || '',
              collegeShortName: college?.collegeShortName || '',
              collegeHiddenName: college?.collegeHiddenName || '',
              collegeName: college?.collegeName,
              deleteDocument: college?.deleteDocument,
            },
          });
        }
      });
      if (i === 0) {
        data.push({
          collegeApplicationPortal: college?.collegeApplicationPortal || '',
          collegeId: college?.id,
          collegeData: {
            id: college?.id,
            collegeApplicationPortal: college?.collegeApplicationPortal || '',
            createdAt: college?.createdAt || '',
            updatedAt: college?.updatedAt || '',
            deletedAt: college?.deletedAt || '',
            collegeId: college?.id || '',
            collegeUrl: college?.collegeUrl || '',
            collegeLogoURL: college?.collegeLogoURL || '',
            collegeShortName: college?.collegeShortName || '',
            collegeHiddenName: college?.collegeHiddenName || '',
            collegeName: college?.collegeName,
            deleteDocument: college?.deleteDocument,
          },
        });
      }
    });
    const sortedData = sortData(data);
    adminStore.setCollegeListData(sortedData);
    // eslint-disable-next-line
  }, [applicationDetail, collegeList]);

  const { data: applicationsData, isFetching: isFetchingApplicationsData } =
    useStoryItemListener(
      APPLICATIONS,
      firebaseDataType.METADATA,
      [
        {
          property: 'owner',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: owner,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      owner
    );

  useEffect(() => {
    const filteredApplications = isParent
      ? checkReviewer(applicationsData)
      : applicationsData;
    const applicationAllData = filteredApplications?.map((college) => {
      const template = adminStore.collegeListData?.find(
        (collegeData) => collegeData?.collegeId === college?.collegeId
      );
      return {
        ...template,
        ...college,
        label: template?.collegeData?.collegeName,
        collegeLogoURL: template?.collegeData?.collegeLogoURL,
      };
    });
    const utilData = addUtilityType(
      applicationAllData,
      addStoryItemTypes.APPLICATIONS
    );
    const appDataWithProgress = addApplicationProgressValue(utilData);
    applicationStore.setApplications(sortData(appDataWithProgress));
    //eslint-disable-next-line
  }, [
    applicationsData,
    adminStore.collegeListData,
    isFetchingApplicationsData,
  ]);

  const handleShareUtility = async (teamMemberData) => {
    const payload = {
      teamId: teamStore.selectedTeam?.id,
      itemType: CIO_SHARE_ITEM_TYPES[myHubStore.utilityType].name,
      itemName: myHubStore.utilityName,
      userId: profileStore.profile?.uid,
      senderFirstName: profileStore.profile?.firstName,
      senderLastName: profileStore.profile?.lastName,
      senderEmail: profileStore.profile?.email,
      teamMembers: teamMemberData,
      teamName: teamStore.selectedTeam?.teamName,
      destinationLink:
        CIO_SHARE_ITEM_TYPES[myHubStore.utilityType].destinationLink,
    };

    const result = await axiosPost(ApiRoutes?.SHARE_ITEM, payload);

    if (result.data.status === 'success') {
      openSnackbar('Item shared successfully!');
      myHubStore.setIsShareUtility(false);
      myHubStore.setUtilityId('');
      myHubStore.setUtilityType('');
      myHubStore.setUtilityName('');
    }
    return result;
  };

  const teamDataReviewers = teamStore?.selectedTeam?.id
    ? teamStore?.selectedTeam?.teamMembers
        ?.filter((profile) => profile?.invite_status === INVITE_STATUS.JOINED)
        ?.filter((member) => member.email !== profileStore.profile?.email)
        ?.map((profile) => ({
          email: profile.email,
          id: profile.uid,
          photoURL: profile?.photoURL,
          label: profile?.firstName + ' ' + profile?.lastName,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
        }))
    : [];

  return (
    <>
      <TeamMemberContainer />
      {profileStore.profile?.uid && (
        <ChatWindowContainer profileUserId={profileStore.profile?.uid} />
      )}
      {profileStore.profile?.uid && (
        <MyAccountContainer profileUserId={profileStore.profile?.uid} />
      )}
      <MyEssayContainer />
      {profileStore.profile?.uid && <ReferAFriendContainer />}
      {profileStore.profile?.uid &&
        [
          userTypeKeywords.FAMILY,
          userTypeKeywords.PRIVATE_COUNSELOR,
          userTypeKeywords.SCHOOL_COUNSELOR,
          userTypeKeywords.TEACHER,
        ].includes(profileStore.profile?.profileType) && <MyStudentContainer />}
      {profileStore.profile?.profileType === userTypeKeywords.ADMINISTRATOR && (
        <AdminContainer />
      )}
      <MyApplicationContainer />
      <GlobalSnackbar />
      {myHubStore.isShareUtility ? (
        <ShareUtility
          open={myHubStore.isShareUtility}
          onClose={() => myHubStore.setIsShareUtility(false)}
          onCancel={() => myHubStore.setIsShareUtility(false)}
          utilityId={myHubStore.utilityId}
          onContinue={(teamMembers) => handleShareUtility(teamMembers)}
          reviewers={filterUniAndTeamUniversily(teamDataReviewers)}
          teamMemberData={filterUniAndTeamUniversily(teamDataReviewers)}
        />
      ) : null}
    </>
  );
};

export default AppContainer;
