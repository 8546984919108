import { Avatar, Divider, Drawer, IconButton } from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/svgs/Logo.svg';
import { ReactComponent as DropDown } from '../../assets/svgs/addDetail.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import GroupIcon from '@mui/icons-material/Group';
import { ReactComponent as SidebarZeroState } from '../../assets/svgs/sidebar_zero_state.svg';
import { useRef, useState } from 'react';
import * as ROUTES from '../../navigation/constants';
import { ReactComponent as ReferAFriendSVG } from '../../assets/svgs/ReferAFriendSVG.svg';
import { getProfileByUserId } from '../../firebase/services/user';
import useProfileStore from '../../stores/ProfileStore';
import { useEffect } from 'react';
import useStudentStore from '../../stores/StudentStore';
import { signOut } from '../../firebase/services/auth.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStoryItemListener } from '../../hooks';
import { REVIEWER } from '../../firebase/constants';
import { firebaseQueryOperators } from '../../firebase/queryBuilder';
import { firebaseDataType } from '../../constants/other';
import Button from '../shared/Button';
import useMyStudentStore from '../../stores/MyStudentStore';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import { MdHome } from 'react-icons/md';
import { MdViewCarousel } from 'react-icons/md';
import { MdAssignment } from 'react-icons/md';
import { MdDashboard } from 'react-icons/md';
import Tooltip from '../shared/Tooltip';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import SchoolIcon from '@mui/icons-material/School';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import useTeamStore from '../../stores/TeamStore';
import { useShallow } from 'zustand/react/shallow';
import { INVITE_STATUS } from '../../utils/utlityTypes';
const drawerWidth = 240;
const miniDrawerWidth = 80;

export const Sidebar = ({ isSideBarOpen, setIsSideBarOpen }) => {
  const [studentData, setStudentData] = useState([]);
  const [studentDataDetails, setStudentDataDetails] = useState({});
  const studentStore = useStudentStore();
  const navigate = useNavigate();
  const profileStore = useProfileStore();
  const { setIsAddStudent } = useMyStudentStore();
  const { data: reviewerData, isFetching: isFetchingReviewerData } =
    useStoryItemListener(
      REVIEWER,
      firebaseDataType.METADATA,
      [
        {
          property: 'email',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: profileStore.profile?.email,
        },
        {
          property: 'deleteDocument',
          operator: firebaseQueryOperators.EQUAL_TO,
          value: false,
        },
      ],
      profileStore.profile?.uid
    );
  const { teamID } = useParams();
  const isParent = profileStore?.isParent;
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef();
  const teamStore = useTeamStore(
    useShallow((state) => ({
      selectedTeam: state.selectedTeam,
      teams: state.teams,
      setSelectedTeam: state.setSelectedTeam,
    }))
  );
  const location = useLocation();
  useEffect(() => {
    const studentList = reviewerData?.filter((student) => {
      if (
        student?.reviewerId === profileStore.profile?.uid &&
        student?.isStudentAccepted
      ) {
        return student;
      } else if (!student?.reviewerId && student?.isAccepted) {
        return student;
      }
    });

    setStudentData(studentList);
    studentStore.setIsFetching(isFetchingReviewerData);
    studentStore.setReviewerIdList(studentList?.map((obj) => obj?.id));
    // eslint-disable-next-line
  }, [profileStore.profile?.email, reviewerData, isFetchingReviewerData]);

  const studentDataList = async () => {
    return studentData && studentData.length > 0
      ? await Promise.all(
          studentData?.map(async (s) => {
            return await getProfileByUserId(s.createdBy);
          })
        )
      : [];
  };

  useEffect(() => {
    studentStore.setIsFetching(false);
    let res = studentDataList();
    res.then((result) => {
      if (result) {
        studentStore.setIsFetching(true);
        studentStore.setStudent(result);
        setStudentDataDetails(result);
      } else {
        studentStore.setIsFetching(false);
      }
    });
    // eslint-disable-next-line
  }, [studentData]);

  const lowerMenuItemsOfSidebar = [
    {
      id: 0,
      menuName: 'Refer a Friend',
      icon: <ReferAFriendSVG width={30} height={30} />,
      navigation: ROUTES.INVITE_FRIEND,
    },
    {
      id: 3,
      menuName: 'My Teams',
      icon: <GroupIcon width={30} height={20} />,
      navigation: ROUTES.MY_TEAM,
    },
    {
      id: 1,
      menuName: 'My Account',
      icon: <PersonIcon width={30} height={20} />,
      navigation: ROUTES.MY_ACCOUNT,
    },
    {
      id: 2,
      menuName: 'Log Out',
      icon: <LogoutIcon className="" width={30} height={25} />,
    },
  ];

  const selectedTeam = teamStore?.selectedTeam;
  const selectedTeamStudentUID = selectedTeam?.studentUID || selectedTeam?.id;
  const selectedTeamStudentFirstName = selectedTeam?.studentFirstName;
  const selectedTeamStudentPhotoURL = selectedTeam?.studentPhotoURL;
  const selectedTeamName = selectedTeam?.teamName;
  const studentMenuItemsOfSidebar = [
    {
      id: 0,
      menuName: 'Hub',
      icon: (
        <MdDashboard
          size={24}
          className={`${
            !selectedTeamStudentUID ? 'text-grey-200' : 'text-grey-600'
          }
          ${
            window.location.pathname === `/${selectedTeam?.id}/myHub` &&
            'text-brand-blue'
          }
          `}
        />
      ),
      navigation: `/${selectedTeam?.id}/myHub`,
    },
    {
      id: 1,
      menuName: 'Story',
      icon: (
        <MdViewCarousel
          size={24}
          className={`${
            !selectedTeamStudentUID ? 'text-grey-200' : 'text-grey-600'
          }
          ${
            window.location.pathname === `/${selectedTeam?.id}/myStory` &&
            'text-brand-blue'
          }
          `}
        />
      ),
      navigation: `/${selectedTeam?.id}/myStory`,
    },
    {
      id: 2,
      menuName: 'Essays',
      icon: (
        <MdAssignment
          size={24}
          className={`${
            !selectedTeamStudentUID ? 'text-grey-200' : 'text-grey-600'
          } ${
            window.location.pathname === `/${selectedTeam?.id}/myEssay` &&
            'text-brand-blue'
          }`}
        />
      ),
      navigation: `/${selectedTeam?.id}/myEssay`,
    },
    {
      id: 3,
      menuName: 'Colleges',
      icon: (
        <SchoolIcon
          size={24}
          className={`${
            !selectedTeamStudentUID ? 'text-grey-200' : 'text-grey-600'
          } ${
            window.location.pathname ===
              '/' + selectedTeam?.id + ROUTES.MY_COLLEGES && 'text-brand-blue'
          }`}
        />
      ),
      navigation: '/' + selectedTeam?.id + ROUTES.MY_COLLEGES,
    },
  ];

  // Same as when selected team from parent view header
  const onSelectTeam = async (student) => {
    // If the newly selected student is the same, do nothing
    if (student?.studentEmail === teamStore.selectedTeam?.studentEmail) {
      return;
    }
    setIsOpen(false);
    const collabProfiles = await Promise.all(
      student?.teamMembers?.map(async (member) => {
        let memberDetails = { ...member };
        if (member?.uid) {
          const memberProfile = await getProfileByUserId(member?.uid);
          console.log({ memberProfile });
          if (memberProfile?.length > 0) {
            const { photoURL, firstName, lastName } = memberProfile[0];
            memberDetails = {
              ...memberDetails,
              photoURL,
              firstName,
              lastName,
            };
          }
        }
        return memberDetails;
      })
    );

    teamStore?.setSelectedTeam({
      ...student,
      teamMembers: collabProfiles,
    });
    // TODO: Check if this is needed, above state sets the same value
    studentStore?.setStudentId(student);
    studentStore.setIsSideBarOptions(true);
    // Extract the current pathname without the teamID
    const pathWithoutTeamID = location.pathname.replace(/^\/[^/]+/, '');

    // Navigate to the same route but with the new teamID
    // navigate(`/${teamStore?.teams[0]?.id}${pathWithoutTeamID}`, {
    //   replace: true,
    // });

    return;
  };
  // If there is only one team and the parent has joined the team, select the team
  useEffect(() => {
    const hasParentJoinedTeam =
      teamStore?.teams[0]?.teamMembers.find(
        (member) => member.email === profileStore?.profile?.email
      )?.status === INVITE_STATUS.JOINED;
    if (!profileStore?.isParent && teamStore?.teams?.length === 1) {
      onSelectTeam(teamStore?.teams[0]);

      const pathWithoutTeamID = location.pathname.replace(/^\/[^/]+/, '');

      // these routes require teamID to be present in the URL hence redirecting to the same route with teamID
      const checkRoutesWithTeamId =
        location.pathname?.includes('myHub') ||
        location.pathname?.includes('myStory') ||
        location.pathname?.includes('myEssay') ||
        location.pathname?.includes('myColleges');
      if (checkRoutesWithTeamId) {
        if (pathWithoutTeamID) {
          navigate(
            `/${teamStore?.teams[0]?.id}${
              pathWithoutTeamID || location.pathname
            }`,
            {
              replace: true,
            }
          );
        } else {
          navigate(`/${teamStore?.teams[0]?.id}/myHub`, {
            replace: true,
          });
        }
      }

      // else navigate to the same route without teamID
    }
    if (profileStore?.isParent && teamStore?.teams?.length === 1) {
      onSelectTeam(teamStore?.teams[0]);

      //TODO (RUTUJA): Check if this is needed
      // const pathWithoutTeamID = location.pathname.replace(/^\/[^/]+/, '');
      // Navigate to the same route but with the new teamID
      // navigate(`/${teamStore?.teams[0]?.id}${pathWithoutTeamID}`, {
      //   replace: true,
      // });
    }
  }, [teamStore?.teams?.length]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isSideBarOpen ? drawerWidth : miniDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isSideBarOpen ? drawerWidth : miniDrawerWidth,
          transition: 'width 0.3s',
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          zIndex: 79, // Ensure the drawer is below the Essay/honor/Activity modal
        },
      }}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: isSideBarOpen ? 'space-between' : 'center',
              padding: '20px',
            }}
          >
            {isSideBarOpen && <LogoIcon />}
            <IconButton onClick={() => setIsSideBarOpen(!isSideBarOpen)}>
              {isSideBarOpen ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <Divider />
          {isParent ? (
            <Tooltip
              title={!isSideBarOpen ? 'All students' : ''}
              placement="right"
            >
              <div
                className={`flex items-center gap-3 cursor-pointer px-5 py-4 hover:bg-grey-50 hover:text-grey-900 ${
                  window.location.pathname === '/myHome' && 'bg-brand-blue-50'
                }`}
                onClick={() => {
                  navigate('/myHome');
                  setIsSideBarOpen(false);
                }}
              >
                <MdHome
                  size={24}
                  className={`text-grey-500 hover:bg-grey-50 hover:text-grey-900 ${
                    window.location.pathname === ROUTES.MY_COLLEGES &&
                    'text-brand-blue'
                  }`}
                />
                {isSideBarOpen && (
                  <div className="text-grey-500">All students</div>
                )}
              </div>
            </Tooltip>
          ) : null}
          <Divider />
          {teamStore?.teams?.length === 0 ? (
            isSideBarOpen ? (
              <div className="px-5 py-2 flex-1 flex-col flex">
                <div className=" bg-[#F7F7F7] flex-1 flex flex-col gap-2 rounded-lg items-center p-10">
                  <div className="bg-[#F1F1F1] rounded-md ">
                    <SidebarZeroState />
                  </div>
                  <div className="text-center text-base leading-5 font-semibold">
                    Start Inviting Students
                  </div>
                  <Button
                    onClick={() => setIsAddStudent(true)}
                    className="!bg-[#B3956E]"
                  >
                    Invite Now
                  </Button>
                </div>
              </div>
            ) : (
              <Tooltip
                placement="right"
                title={!isSideBarOpen ? 'Invite Students' : ''}
              >
                <div
                  className={`flex items-center gap-3 cursor-pointer px-5 py-4 hover:bg-grey-50 hover:text-grey-900 `}
                  onClick={() => {
                    setIsAddStudent(true);
                    setIsSideBarOpen(false);
                  }}
                >
                  <PersonAddIcon />
                  {isSideBarOpen && <div>Invite Students</div>}
                </div>
              </Tooltip>
            )
          ) : // Icon to invite students
          null}
          {teamStore?.teams?.length !== 0 && (
            <div className="pt-4 flex flex-col">
              <div
                className={`hidden self-start sm:flex items-center gap-2 p-3 rounded-lg ml-2`}
                ref={button}
              >
                {selectedTeamStudentUID && (
                  <Tooltip
                    title={!isSideBarOpen ? selectedTeamStudentFirstName : ''}
                    placement="right"
                  >
                    <Avatar
                      sx={{ width: 24, height: 24, cursor: 'pointer' }}
                      src={
                        selectedTeamStudentPhotoURL
                          ? selectedTeamStudentPhotoURL
                          : selectedTeamStudentFirstName?.charAt(0)
                      }
                      onClick={() => {
                        if (!isSideBarOpen) {
                          setIsSideBarOpen(true);
                        } else {
                          setIsOpen(true);
                        }
                      }}
                    />
                  </Tooltip>
                )}
                {isSideBarOpen && selectedTeamStudentUID && (
                  <>
                    <div>{selectedTeamName ? selectedTeamName : ''}</div>
                    <div className="flex items-center gap-2">
                      {isParent && (
                        <IconButton onClick={() => setIsOpen(true)}>
                          <DropDown
                            className={`${
                              isOpen &&
                              'rotate-180 transition duration-200 ease-in'
                            }`}
                          />
                        </IconButton>
                      )}
                    </div>
                  </>
                )}
              </div>
              {studentMenuItemsOfSidebar?.map((item) => (
                <Tooltip
                  title={!isSideBarOpen ? item.menuName : ''}
                  key={item.id}
                  placement="right"
                >
                  <div
                    key={item.id}
                    className={`flex py-4 px-5 flex-1 items-center gap-5 cursor-pointer  hover:bg-grey-50 hover:text-grey-900 ${
                      !selectedTeamStudentUID ? 'text-grey-200' : ''
                    } ${
                      window.location.pathname === item.navigation &&
                      'bg-brand-blue-50'
                    }`}
                    onClick={() => {
                      if (!selectedTeamStudentUID) return;
                      if (item.menuName !== 'Log Out') {
                        navigate(item.navigation);
                      }

                      setIsSideBarOpen(false);
                    }}
                  >
                    {item.icon}
                    {isSideBarOpen && <div>{item.menuName}</div>}
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
        </div>
        {isSideBarOpen && <Divider />}
        <div className="flex flex-col">
          {lowerMenuItemsOfSidebar?.map((item) => (
            <Tooltip
              title={!isSideBarOpen ? item.menuName : ''}
              key={item.menuName}
              placement="right"
            >
              <div
                id={item.menuName.split(' ').join('-')}
                key={item.id}
                className={`flex px-5 py-4 flex-1 items-center gap-5 cursor-pointer text-grey-500 hover:bg-grey-50 hover:text-grey-900 ${
                  window.location.pathname === item.navigation &&
                  'bg-brand-blue-50'
                }`}
                onClick={() => {
                  if (item.menuName !== 'Log Out') {
                    navigate(item.navigation);
                  }
                  if (item.menuName === 'Log Out') {
                    studentStore.setSelectedStudent(null);
                    signOut();
                    profileStore.setProfile(null);
                  }
                  setIsSideBarOpen(false);
                }}
              >
                {item.icon}
                {isSideBarOpen && <div>{item.menuName}</div>}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
      {/* Sidebar menu items */}
      <Menu
        open={isOpen}
        anchorEl={button.current}
        onClose={() => setIsOpen(false)}
        getContentAnchorEl={null}
      >
        {teamStore?.teams?.length === 0 && (
          <div className="flex flex-col items-center text-center justify-center py-2 px-2">
            <div className="text-grey-200 text-sm ">
              You can view and toggle between <br />
              students profiles once you <br />
              are added as a Team Member.
            </div>
          </div>
        )}
        {studentStore?.student?.flat().length !== 0 && (
          <MenuItem
            onClick={() => {
              teamStore?.setSelectedTeam(null);
              navigate('/myHome');
              setIsOpen(false);
            }}
          >
            <div className="flex justify-center">
              <div>Select Student</div>
            </div>
          </MenuItem>
        )}
        {teamStore?.teams?.map((student) => (
          <MenuItem
            key={student?.studentUID}
            onClick={() => {
              setIsOpen(false);
              onSelectTeam(student);
              navigate(`/${selectedTeam?.id}/myHub`);
            }}
          >
            <div className="flex items-center gap-2">
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={
                  student?.studentPhotoURL
                    ? student?.studentPhotoURL
                    : student?.studentFirstName?.charAt(0)
                }
              />
              <div>{student?.teamName}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Drawer>
  );
};
